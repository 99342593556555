// import React, { useState } from 'react';
// import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import './navbar.css';
// // import logo from "../../assets/logo.png"

// const Navbar = () => {
//   const [toggleMenu, setToggleMenu] = useState(false);

//   return (
//     <div className="gpt3__navbar">
//       <div className="gpt3__navbar-links">
//         <div className="gpt3__navbar-links_logo">
//           {/* <img src={logo} /> */}
//         </div>
//         <div className="gpt3__navbar-links_container">
//           <p><a href="#about">About</a></p>
//           <p><a href="#speakers">Speakers</a></p>
//           <p><a href="#sponsors">Sponsors</a></p>
//           <p><a href="#sessions">Sessions</a></p>
//           <p><a href="#contact">Contact Us</a></p>
//         </div>
//         <div className="container mx-auto px-4 text-center">
//           <h1 className="text-4xl font-bold text-white">Dou<span className="text-transparent bg-clip-text bg-teal-400">it</span> Pour Mot</h1>
//           <h2 className="text-xl text-gray-100 mt-4">Connect & Evolve: Cultivating Innovation and Inspiration for All</h2>
//           <h3 className="text-2xl text-white mt-6">2024 Push Event</h3>
//           <button className="bg-teal-400 text-white font-bold py-2 px-4 rounded-full mt-8 hover:bg-teal-500">Register</button>
//         </div>
//       </div>
//       <div className="gpt3__navbar-menu">
//         {toggleMenu
//           ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
//           : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
//         {toggleMenu && (
//         <div className="gpt3__navbar-menu_container scale-up-center">
//           <div className="gpt3__navbar-menu_container-links">
//             <p><a href="#about">About</a></p>
//             <p><a href="#speakers">Speakers</a></p>
//             <p><a href="#sponsors">Sponsors</a></p>
//             <p><a href="#sessions">Sessions</a></p>
//             <p><a href="#contact">Contact Us</a></p>
//           </div>
//         </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Navbar;





// import React from 'react';
// import background from "../../assets/Douala.png"


// const Navbar = () => {
//   return (
//     <div className="relative h-screen overflow-hidden">
//       <div className="absolute inset-0 z-0">
//         <img src={background} alt="Hero Background" className="object-cover w-full h-full" />
//       </div>
//     </div>
//   );
// };

// export default Navbar;




// // Navbar with Google form
// import React, { useState, useEffect } from 'react';
// import './navbar.css'

// const paragraphStyles = {
//   fontFamily: 'Poppins',
//   fontSize: '12.8px',
//   fontWeight: 400,
//   lineHeight: '19.2px',
//   textAlign: 'left'
// };

// const Navbar = () => {
//   const [showForm, setShowForm] = useState(false);
//   const handleRegister = () => {
//     setShowForm(true);
//   };
//   return (
//     <div className="relative">
//       <video className="absolute inset-0 object-cover w-full h-full z-0" autoPlay loop muted>
//         <source src={require("../../assets/Background.mp4")} type="video/mp4" />
//       </video>
//       <header className="relative h-screen z-10">
//         <div className="absolute inset-0 bg-black opacity-50"></div>
//         <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
//           <h1 className="font-rajdhana text-1xl font-bold text-white text-center">July 18th - 20th, 2024<span className="text-transparent bg-clip-text bg-teal-400">Hello </span>Douala, Vallée Besengue Immeuble Tout Pour Moto</h1>
//           <h2 className="font-rajdhani text-green-400 mt-4 font-semibold leading-tight text-center">Connect & Evolve: Cultivating Innovation and Inspiration for All 2024 Push Event</h2>
//           <button 
//             className="font-rajdhana bg-green-400 text-white font-bold px-4 mt-8 hover:bg-green-500" 
//             style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "4rem", paddingRight: "4rem"}}
//           >
//           Register
//         </button>
//         </div>
        
//         <nav className="absolute top-4 right-4 md:top-8 md:right-8">
//           <ul className="hidden md:flex space-x-4">
//             <li><a href="#about" className="text-green-400 hover:text-indigo-300 transition duration-300">About</a></li>
//             <li><a href="#speakers" className="text-green-400 hover:text-indigo-300 transition duration-300">Speakers</a></li>
//             <li><a href="#sponsors" className="text-green-400 hover:text-indigo-300 transition duration-300">Sponsors</a></li>
//             <li><a href="#sessions" className="text-green-400 hover:text-indigo-300 transition duration-300">Sessions</a></li>
//             <li><a href="#contact" className="text-green-400 hover:text-indigo-300 transition duration-300">Contact Us</a></li>
//           </ul>
//         </nav>
        
//         <div className="text-nav absolute bottom-4 left-4 w-full flex justify-center">
//           <div className="section__padding flex space-x-4">
//             <p style={paragraphStyles}>MARKETING</p>
//             <p style={paragraphStyles}>DESIGN</p>
//             <p style={paragraphStyles}>TECHNOLOGY</p>
//             <p style={paragraphStyles}>ENTREPRENEURSHIP</p>
//             <p style={paragraphStyles}>BUSINESS</p>
//             <p style={paragraphStyles}>AGRICULTURE</p>
//             <p style={paragraphStyles}>MARKETING</p>
//             <p style={paragraphStyles}>DESIGN</p>
//             <p style={paragraphStyles}>TECHNOLOGY</p>
//             <p style={paragraphStyles}>ENTREPRENEURSHIP</p>
//             <p style={paragraphStyles}>BUSINESS</p>
//             <p style={paragraphStyles}>AGRICULTURE</p>
//           </div>
//         </div>

//         <div className="absolute bottom-4 right-4 md:hidden">
//           <button className="text-white text-2xl focus:outline-none">&#9776;</button>
//         </div>
//       </header>
//       {showForm && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-8 rounded-lg shadow-lg">
//             <iframe 
//               src="https://docs.google.com/forms/d/e/1FAIpQLSewVgMiS4pyteP1fB47AG2mRUrVIKcf7aqRCr2mCnPRamM0Nw/viewform?embedded=true" 
//               width="1000px" 
//               height="500px" 
//               frameborder="0" 
//               marginheight="0" 
//               marginwidth="0"
//               className="w-full h-full"
//             >
//               Loading…
//             </iframe>
//             <button 
//               className="absolute top-4 right-4 text-xl text-gray-600 hover:text-gray-800 focus:outline-none"
//               onClick={() => setShowForm(false)} 
//             >
//               &times;
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navbar;








// import React, { useState } from 'react';
// import './navbar.css';
// import avatar1 from '../../assets/avatar.png'; 

// const paragraphStyles = {
//   fontFamily: 'Poppins',
//   fontSize: '12.8px',
//   fontWeight: 400,
//   lineHeight: '19.2px',
//   textAlign: 'left'
// };

// const Navbar = () => {
//   const [showForm, setShowForm] = useState(false);
//   const [menuActive, setMenuActive] = useState(false); // Add state for menu

//   const handleRegisterClick = () => {
//     setShowForm(true);
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const toggleMenu = () => {
//     setMenuActive(prevMenuActive => !prevMenuActive); // Toggle menu state based on previous state
//   };

//   return (
//     <div className="relative">
//       <video className="absolute inset-0 object-cover w-full h-full" autoPlay loop muted>
//         <source src={require("../../assets/Background.mp4")} type="video/mp4" />
//       </video>
//       <header className="relative h-screen">
//         <nav className="relative top-4 right-4 md:top-8 md:right-8">
//           <ul className="hidden md:flex space-x-4">
//             <li><a href="#about" className="text-green-400 hover:text-indigo-300 transition duration-300">About</a></li>
//             <li><a href="#speakers" className="text-green-400 hover:text-indigo-300 transition duration-300">Speakers</a></li>
//             <li><a href="#sponsors" className="text-green-400 hover:text-indigo-300 transition duration-300">Sponsors</a></li>
//             <li><a href="#sessions" className="text-green-400 hover:text-indigo-300 transition duration-300">Sessions</a></li>
//             <li><a href="#contact" className="text-green-400 hover:text-indigo-300 transition duration-300">Contact Us</a></li>
//           </ul>
//         </nav>
//         <div className="relative inset-0 bg-black opacity-50"></div>
//         <div className="relative inset-0 flex flex-col items-center justify-center text-white">
//           <div className="text-center">
//             <h1 className="font-rajdhana text-1xl font-bold text-white">July 18th - 20th, 2024<span className="text-transparent bg-clip-text bg-teal-400">Hello </span>Douala, Vallée Besengue Immeuble Tout Pour Moto</h1>
//             <h2 className="font-rajdhani text-green-400 mt-4 font-semibold leading-tight">Connect & Evolve: Cultivating Innovation and Inspiration for All 2024 Push Event</h2>
//           </div>
//           <button onClick={handleRegisterClick} className="font-rajdhana bg-green-400 text-white font-bold px-4 mt-8 hover:bg-green-500" style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: "4rem", paddingRight: "4rem"}}>Register</button>
//         </div>
        
        
        
//         <div className="text-nav relative bottom-4 left-0 right-0 flex justify-center">
//           <div className="section__padding flex flex-wrap space-x-4">
//             <p style={paragraphStyles}>MARKETING</p>
//             <p style={paragraphStyles}>DESIGN</p>
//             <p style={paragraphStyles}>TECHNOLOGY</p>
//             <p style={paragraphStyles}>ENTREPRENEURSHIP</p>
//             <p style={paragraphStyles}>BUSINESS</p>
//             <p style={paragraphStyles}>AGRICULTURE</p>
//             <p style={paragraphStyles}>MARKETING</p>
//             <p style={paragraphStyles}>DESIGN</p>
//             <p style={paragraphStyles}>TECHNOLOGY</p>
//             <p style={paragraphStyles}>ENTREPRENEURSHIP</p>
//             <p style={paragraphStyles}>BUSINESS</p>
//             <p style={paragraphStyles}>AGRICULTURE</p>
//           </div>
//         </div>

//         <div className="absolute top-4 right-4 md:top-8 md:right-8 md:hidden">
//           <button className="text-white text-2xl focus:outline-none" onClick={toggleMenu}>&#9776;</button>
//         </div>

//       </header>

//       <div className={`gpt3__navbar-menu ${menuActive ? 'active' : ''}`}> 
//         <div className="gpt3__navbar-menu_container">
//           <p><a href="#about">About</a></p>
//           <p><a href="#speakers">Speakers</a></p>
//           <p><a href="#sponsors">Sponsors</a></p>
//           <p><a href="#sessions">Sessions</a></p>
//           <p><a href="#contact">Contact Us</a></p>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default Navbar;
















import React, { useState, useEffect, useRef } from 'react';
import './navbar.css';
import avatar1 from '../../assets/avatar.png';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logopush.png';

const paragraphStyles = {
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '12.8px',
  fontWeight: 400,
  lineHeight: '19.2px',
  textAlign: 'left',
  display: 'inline-block',
  margin: '0 10px', // Add some space between the paragraphs
  color: 'white' // Set text color to white
};

const Navbar = () => {
  const [showForm, setShowForm] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const { t, i18n } = useTranslation();
  const formRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        setShowForm(false);
      }
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleRegisterClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const toggleMenu = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  };

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  const scrollToTickets = () => {
    const ticketsSection = document.getElementById('tickets');
    if (ticketsSection) {
      ticketsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = () => {
    setMenuActive(false);
  };

  return (
    <div className="relative">
      <video className="absolute inset-0 object-cover w-full h-full" autoPlay loop muted>
        <source src={require("../../assets/Background.mp4")} type="video/mp4" />
      </video>
      <header className="relative h-screen">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <div className="text-center" style={{ padding: "1rem 1rem 0 1rem" }}>
            <h1 className="font-rajdhana text-1xl font-bold text-white">July 18th - 20th, 2024</h1>
            <h2 className="font-rajdhani text-green-400 mt-4 font-semibold leading-tight">{t('connectAndEvolve')}</h2>
          </div>
          <div className="flex flex-col md:flex-row mt-8" style={{ gap: "1rem", marginBottom: "4rem" }}>
            <button onClick={handleRegisterClick} className="font-rajdhana bg-green-400 text-white font-bold px-4 hover:bg-green-500" style={{ padding: "10px 4rem" }}>
              {t('register')}
            </button>
            <button onClick={scrollToTickets} className="font-rajdhana text-white font-bold px-4 hover:bg-green-500" style={{ padding: "10px 4rem", border: "1px solid white" }}>
              {t('getTickets')}
            </button>
          </div>
        </div>

        <div className="absolute top-4 left-4 md:top-10 md:left-8">
          <img src={logo} alt="Logo" className="h-8 ml-4" />
        </div>

        <nav className="absolute top-4 right-4 md:top-10 md:right-8">
          <ul className="hidden md:flex space-x-4" style={{ fontFamily: "Rajdhani", fontSize: "16px" }}>
            <li style={{ marginRight: '25px' }}><a href="#about" className="text-green-400 hover:text-green-600 transition duration-300">{t('about')}</a></li>
            <li style={{ marginRight: '25px' }}><a href="#speakers" className="text-green-400 hover:text-green-600 transition duration-300">{t('speakers')}</a></li>
            <li style={{ marginRight: '25px' }}><a href="#sponsors" className="text-green-400 hover:text-green-600 transition duration-300">{t('sponsors')}</a></li>
            <li style={{ marginRight: '25px' }}><a href="#sessions" className="text-green-400 hover:text-green-600 transition duration-300">{t('sessions')}</a></li>
            <li style={{ marginRight: '25px' }}><a href="#contact" className="text-green-400 hover:text-green-600 transition duration-300">{t('contact')}</a></li>
            <li style={{ marginRight: '25px' }}>
              <select onChange={changeLanguage} defaultValue={i18n.language} className="text-green-400 hover:text-green-600 transition duration-300 bg-transparent border-none">
                <option value="en">{t('English')}</option>
                <option value="fr">{t('Francais')}</option>
              </select>
            </li>
          </ul>
        </nav>

        <div className="absolute top-4 right-4 md:top-8 md:right-8 md:hidden">
          <button className="text-white text-2xl focus:outline-none" onClick={toggleMenu}>&#9776;</button>
        </div>
      </header>

      {/* Menu */}
      {menuActive && (
        <div ref={menuRef} className={`gpt3__navbar-menu ${menuActive ? 'active' : ''}`}>
          <div className="gpt3__navbar-menu_container" style={{ fontFamily: 'Montserrat', fontSize: "16px", marginTop: "30px", marginRight: "10px" }}>
            <p><a href="#about" onClick={handleLinkClick}>{t('about')}</a></p>
            <p><a href="#speakers" onClick={handleLinkClick}>{t('speakers')}</a></p>
            <p><a href="#sponsors" onClick={handleLinkClick}>{t('sponsors')}</a></p>
            <p><a href="#sessions" onClick={handleLinkClick}>{t('sessions')}</a></p>
            <p><a href="#contact" onClick={handleLinkClick}>{t('contact')}</a></p>
            <select onChange={changeLanguage} defaultValue={i18n.language} className="text-green-400 hover:text-indigo-300 transition duration-300 bg-transparent border-none">
              <option value="en">{t('English')}</option>
              <option value="fr">{t('French')}</option>
            </select>
          </div>
        </div>
      )}

      {/* Infinite Scroll Section */}
      <div className="text-nav">
        <div className="scrolling-text">
          <p style={paragraphStyles}>{t('marketing')}</p>
          <p style={paragraphStyles}>{t('design')}</p>
          <p style={paragraphStyles}>{t('technology')}</p>
          <p style={paragraphStyles}>{t('entrepreneurship')}</p>
          <p style={paragraphStyles}>{t('business')}</p>
          <p style={paragraphStyles}>{t('agriculture')}</p>
          <p style={paragraphStyles}>{t('marketing')}</p>
          <p style={paragraphStyles}>{t('design')}</p>
          <p style={paragraphStyles}>{t('technology')}</p>
          <p style={paragraphStyles}>{t('entrepreneurship')}</p>
          <p style={paragraphStyles}>{t('business')}</p>
          <p style={paragraphStyles}>{t('agriculture')}</p>
          <p style={paragraphStyles}>{t('marketing')}</p>
          <p style={paragraphStyles}>{t('design')}</p>
          <p style={paragraphStyles}>{t('technology')}</p>
          <p style={paragraphStyles}>{t('entrepreneurship')}</p>
          <p style={paragraphStyles}>{t('business')}</p>
          <p style={paragraphStyles}>{t('agriculture')}</p>
        </div>
        <div className="scrolling-text">
          <p style={paragraphStyles}>{t('marketing')}</p>
          <p style={paragraphStyles}>{t('design')}</p>
          <p style={paragraphStyles}>{t('technology')}</p>
          <p style={paragraphStyles}>{t('entrepreneurship')}</p>
          <p style={paragraphStyles}>{t('business')}</p>
          <p style={paragraphStyles}>{t('agriculture')}</p>
          <p style={paragraphStyles}>{t('marketing')}</p>
          <p style={paragraphStyles}>{t('design')}</p>
          <p style={paragraphStyles}>{t('technology')}</p>
          <p style={paragraphStyles}>{t('entrepreneurship')}</p>
          <p style={paragraphStyles}>{t('business')}</p>
          <p style={paragraphStyles}>{t('agriculture')}</p>
          <p style={paragraphStyles}>{t('marketing')}</p>
          <p style={paragraphStyles}>{t('design')}</p>
          <p style={paragraphStyles}>{t('technology')}</p>
          <p style={paragraphStyles}>{t('entrepreneurship')}</p>
          <p style={paragraphStyles}>{t('business')}</p>
          <p style={paragraphStyles}>{t('agriculture')}</p>
        </div>
      </div>

      {/* Register Form */}
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div ref={formRef} className="bg-white p-4 rounded-lg shadow-xl w-full max-w-4xl mx-auto" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <button className="absolute top-4 right-4 text-white" onClick={handleCloseForm}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className="form-container relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScwJ5oqsowGG7j9L2NCMG53mlDbFnfPlKTAQiXxg_a6W_Zm3A/viewform?embedded=true"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title="Google Form"
              >Loading…</iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;










// import React, { useState } from 'react';
// import './navbar.css';
// import avatar1 from '../../assets/avatar.png'; 
// import { useTranslation } from 'react-i18next';
// import logo from '../../assets/logopush.png';

// const paragraphStyles = {
//   fontFamily: 'Montserrat, sans-serif', 
//   fontSize: '12.8px',
//   fontWeight: 400,
//   lineHeight: '19.2px',
//   textAlign: 'left'
// };

// const Navbar = () => {
//   const [showForm, setShowForm] = useState(false);
//   const [menuActive, setMenuActive] = useState(false);
//   const { t, i18n } = useTranslation();
  
//   const handleRegisterClick = () => {
//     setShowForm(true);
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const toggleMenu = () => {
//     setMenuActive(prevMenuActive => !prevMenuActive); 
//   };

//   const changeLanguage = (e) => {
//     const selectedLanguage = e.target.value;
//     i18n.changeLanguage(selectedLanguage);
//   };

//   const scrollToTickets = () => {
//     const ticketsSection = document.getElementById('tickets');
//     if (ticketsSection) {
//       ticketsSection.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <div className="relative">
//       <video className="absolute inset-0 object-cover w-full h-full" autoPlay loop muted>
//         <source src={require("../../assets/Background.mp4")} type="video/mp4" />
//       </video>
//       <header className="relative h-screen">
//         <div className="absolute inset-0 bg-black opacity-50"></div>
//         <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
//           <div className="text-center" style={{padding: "1rem 1rem 0 1rem"}}>
//             <h1 className="font-rajdhana text-1xl font-bold text-white">July 18th - 20th, 2024 <span className="text-transparent bg-clip-text bg-teal-400"> {t('header.message')} Douala, </span> Vallée Besengue Immeuble Tout Pour Moto</h1>
//             <h2 className="font-rajdhani text-green-400 mt-4 font-semibold leading-tight">{t('connectAndEvolve')}</h2>
//           </div>
//           <div className="flex flex-col md:flex-row mt-8" style={{gap: "1rem", marginBottom: "4rem"}}>
//             <button onClick={handleRegisterClick} className="font-rajdhana bg-green-400 text-white font-bold px-4 hover:bg-green-500" style={{padding: "10px 4rem"}}>
//               {t('register')}
//             </button>
//             <button onClick={scrollToTickets} className="font-rajdhana text-white font-bold px-4 hover:bg-green-500" style={{padding: "10px 4rem", border: "1px solid white"}} >
//               {t('getTickets')}
//             </button>
//           </div>

//         </div>

//         <div className="absolute top-4 left-4 md:top-10 md:left-8">
//           <img src={logo} alt="Logo" className="h-8 ml-4" />
//         </div>
        
//         <nav className="absolute top-4 right-4 md:top-10 md:right-8">
//           <ul className="hidden md:flex space-x-4" style={{ fontFamily: "Rajdhani", fontSize: "16px"}}>
//             <li style={{ marginRight: '25px' }}><a href="#about" className="text-green-400 hover:text-green-600 transition duration-300">{t('about')}</a></li>
//             <li style={{ marginRight: '25px' }}><a href="#speakers" className="text-green-400 hover:text-green-600 transition duration-300">{t('speakers')}</a></li>
//             <li style={{ marginRight: '25px' }}><a href="#sponsors" className="text-green-400 hover:text-green-600 transition duration-300">{t('sponsors')}</a></li>
//             <li style={{ marginRight: '25px' }}><a href="#sessions" className="text-green-400 hover:text-green-600 transition duration-300">{t('sessions')}</a></li>
//             <li style={{ marginRight: '25px' }}><a href="#contact" className="text-green-400 hover:text-green-600 transition duration-300">{t('contact')}</a></li>
//             <li style={{ marginRight: '25px' }}>
//               <select onChange={changeLanguage} defaultValue={i18n.language} className="text-green-400 hover:text-green-600 transition duration-300 bg-transparent border-none">
//                 <option value="en">{t('English')}</option>
//                 <option value="fr">{t('Francais')}</option>
//               </select>
//             </li>
//           </ul>
//         </nav>

//         <div className="absolute top-4 right-4 md:top-8 md:right-8 md:hidden">
//           <button className="text-white text-2xl focus:outline-none" onClick={toggleMenu}>&#9776;</button>
//         </div>
//       </header>


//       {/* Menu */}
//       <div className={`gpt3__navbar-menu ${menuActive ? 'active' : ''}`}> 
//         <div className="gpt3__navbar-menu_container" style={{fontFamily: 'Montserrat', fontSize: "16px"}}>
//           <p><a href="#about">{t('about')}</a></p>
//           <p><a href="#speakers">{t('speakers')}</a></p>
//           <p><a href="#sponsors">{t('sponsors')}</a></p>
//           <p><a href="#sessions">{t('sessions')}</a></p>
//           <p><a href="#contact">{t('contact')}</a></p>
//           <select onChange={changeLanguage} defaultValue={i18n.language} className="text-green-400 hover:text-indigo-300 transition duration-300 bg-transparent border-none">
//             <option value="en">{t('English')}</option>
//             <option value="fr">{t('French')}</option>
//           </select>
//         </div>
//       </div>

//       {/* Marketing Categories */}
//       <div className="text-nav absolute bottom-4 left-0 right-0 flex justify-center scrolling-text-container">
//         <div className=" flex space-x-4 justify-center scrolling-cat">
//           <p style={paragraphStyles}>{t('marketing')}</p>
//           <p style={paragraphStyles}>{t('design')}</p>
//           <p style={paragraphStyles}>{t('technology')}</p>
//           <p style={paragraphStyles}>{t('entrepreneurship')}</p>
//           <p style={paragraphStyles}>{t('business')}</p>
//           <p style={paragraphStyles}>{t('agriculture')}</p>
//           <p style={paragraphStyles}>{t('marketing')}</p>
//           <p style={paragraphStyles}>{t('design')}</p>
//           <p style={paragraphStyles}>{t('technology')}</p>
//           <p style={paragraphStyles}>{t('entrepreneurship')}</p>
//           <p style={paragraphStyles}>{t('business')}</p>
//           <p style={paragraphStyles}>{t('agriculture')}</p>
//           <p style={paragraphStyles}>{t('marketing')}</p>
//           <p style={paragraphStyles}>{t('design')}</p>
//           <p style={paragraphStyles}>{t('technology')}</p>
//           <p style={paragraphStyles}>{t('entrepreneurship')}</p>
//           <p style={paragraphStyles}>{t('business')}</p>
//           <p style={paragraphStyles}>{t('agriculture')}</p>
//         </div>
//       </div>
//       {showForm && (
//         <div className="fixed inset-0 flex items-center justify-center ">
//           <div className="absolute inset-0 bg-black opacity-50"></div>
//           <div className="z-50 bg-white p-8 rounded-lg shadow-xl">
//             <button className="absolute top-4 right-4 text-white" onClick={handleCloseForm}>
//               <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
//               </svg>
//             </button>
//             <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScwJ5oqsowGG7j9L2NCMG53mlDbFnfPlKTAQiXxg_a6W_Zm3A/viewform?embedded=true" width="640" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navbar;
