
// import React from 'react';
// import './ticket.css';


// const Ticket  = () => {
//   return (
//     <div className="ticket" id="tickets">
//       <div className="ticket-form">
//         <div className='ticket-info'>
//           <div className="image-container">
//             <h1>Early Bird Ticket</h1>
//             <p>Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae.Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. <br/><br/> Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae.Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac.</p>
//             <button className="bg-black text-white px-4 py-2 my-5">Buy Ticket</button>
//           </div>
//           <div className="image-container">
//             <h1>Community Ticket</h1>
//             <p>Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae.Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. <br/><br/> Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae.Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac.</p>
//             <button className="bg-black text-white px-4 py-2 my-5">Buy Ticket</button>
//           </div>
//           <div className="image-container">
//             <h1>VIP Ticket</h1>
//             <p>Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae.Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. <br/><br/> Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae. Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac. Pellentesque tempus cursus sed vitae.Lorem ipsum dolor sit amet consectetur. Vestibulum ut aliquam platea ac.</p>
//             <button className="bg-black text-white px-4 py-2 my-5">Buy Ticket</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Ticket ;











import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ticket.css';

const Ticket = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post('https://dsapi.tranzak.me/auth/token', {
          appId: process.env.REACT_APP_API_APP_ID,
          appKey: process.env.REACT_APP_API_APP_KEY
        });
        const { data } = response.data;
        setToken(data.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, []);

  const generateUniqueTransactionRef = () => {
    return `myorder${Date.now()}${Math.floor(Math.random() * 1000)}`;
  };

  const handlePayment = async (ticketType) => {
    let amount = 0;
    let description = '';

    switch (ticketType) {
      case 'Standard':
        amount = 15000;
        description = `${t('Standard Ticket')} ${t('ticketPayment')}`;
        break;
      case 'Cocktail':
        amount = 25000;
        description = `${t('Cocktail Ticket')} ${t('ticketPayment')}`;
        break;
      case 'VIP':
        amount = 35000;
        description = `${t('VIP Ticket')} ${t('ticketPayment')}`;
        break;
      case 'Exhibition':
        amount = 50000;
        description = `${t('Exhibition Ticket')} ${t('ticketPayment')}`;
        break;
      default:
        break;
    }

    try {
      const response = await axios.post(
        'https://mycorsproxyulevus-f5eaccea93ce.herokuapp.com/https://dsapi.tranzak.me/xp021/v1/request/create',
        {
          amount: amount,
          currencyCode: 'XAF',
          description: description,
          mchTransactionRef: generateUniqueTransactionRef(),
          returnUrl: 'http://pushevt.com/'
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      console.log(response.data);

      const paymentAuthUrl = response.data.data.links.paymentAuthUrl;

      if (paymentAuthUrl) {
        window.location.href = paymentAuthUrl;
      } else {
        console.error('Payment authorization URL not found in response.');
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        console.error('Conflict error occurred. Please try again later.');
      } else {
        console.error('Error making payment:', error);
      }
    }
  };

  return (
    <div className="ticket" id="tickets">
      <div className="ticket-form">
        <div className='ticket-info grid grid-cols-1 md:grid-cols-2 gap-6'>
          <div className="image-container">
            <h1>{t('earlyBirdTicket')}</h1>
            <p style={{ fontSize: "12px", fontWeight: "400px" }} dangerouslySetInnerHTML={{ __html: t('earlyBirdTicketDescription') }}></p>
            <button className="buttona bg-black text-white px-12 py-2 my-8" onClick={() => handlePayment('Standard')}>
              {t('buyTicket')}
            </button>
          </div>
          <div className="image-container">
            <h1>{t('communityTicket')}</h1>
            <p style={{ fontSize: "12px", fontWeight: "400px" }} dangerouslySetInnerHTML={{ __html: t('communityTicketDescription') }}></p>
            <button className="buttona bg-black text-white px-12 py-2 my-8" onClick={() => handlePayment('Cocktail')}>
              {t('buyTicket')}
            </button>
          </div>
          <div className="image-container">
            <h1 dangerouslySetInnerHTML={{ __html: t('vipTicket')}}></h1>
            <p style={{ fontSize: "12px", fontWeight: "400px" }} dangerouslySetInnerHTML={{ __html: t('vipTicketDescription')}} ></p>
            <button className="buttona bg-black text-white px-12 py-2 my-8" onClick={() => handlePayment('VIP')}>
              {t('buyTicket')}
            </button>
          </div>
          <div className="image-container">
            <h1 dangerouslySetInnerHTML={{ __html: t('exibitionTicket')}}></h1>
            <p style={{ fontSize: "12px", fontWeight: "400px" }} dangerouslySetInnerHTML={{ __html: t('exibitionTicketDescription')}} ></p>
            <button className="buttona bg-black text-white px-12 py-2 my-8" onClick={() => handlePayment('Exhibition')}>
              {t('buyTicket')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
