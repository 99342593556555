// import React from 'react';
// import './feeback.css';
// import avatar1 from '../../assets/avatar.png'; 

// const Feedback = () => {
//   return (
//     <div className="flex flex-col bg-white m-auto p-auto" style={{padding: "4rem 0", fontFamily: "rajdhani"}}>
//       <h1 className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-0 md:mx-20 mx-5 font-bold text-4xl text-gray-800">
//         2024 Feedback
//       </h1>
//       <div className="feedback-container pb-10 hide-scroll-bar">
//         <div className="flex lg:ml-40 md:ml-20 ml-10 ">
//           {[...Array(5)].map((_, index) => (
//             <div className="inline-block px-3" key={index}>
//               <div className="w-64 h-64 max-w-xs overflow-hidden shadow-md bg-green-400 hover:shadow-xl transition-shadow duration-300 ease-in-out" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
//                 <div className="inline-flex px-3 items-center">
//                   <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover" style={{width: "50px", height: "50px"}} />
//                   <div className="p-3">
//                     <p className="font-semibold text-lg text-black">Dave Ndoubissi.</p>
//                     <p className="text-black">Position</p>
//                   </div>
//                 </div>
//                 <p className="p-3 text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eleifend, magna eget convallis ultricies, mauris nulla tincidunt urna, at auctor est ex vitae metus.</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Feedback;







import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import avatar1 from '../../assets/avatar.png';
import feed from '../../assets/feed.png';
import feed1 from '../../assets/feed1.png';
import feed2 from '../../assets/feed2.png';
import feed3 from '../../assets/feed3.png';
import './feeback.css';

const Feedback = () => {
  const { t } = useTranslation();
  const [isScrolling, setIsScrolling] = useState(true);

  const handleToggleScroll = () => {
    setIsScrolling(!isScrolling);
  };

  return (
    <div className="flex flex-col bg-white m-auto p-auto" style={{ padding: "4rem 0", fontFamily: "rajdhani" }}>
      <h1 className="flex lg:px-20 md:px-10 px-5 lg:mx-0 md:mx-20 mx-5 font-bold text-4xl text-gray-800" style={{ fontSize: "30px", fontWeight: "600", paddingBottom: "40px" }}>
        {t('feedbackTitle')}
      </h1>
      <div className={`flex pb-10 hide-scroll-bar infinite-scroll-container ${isScrolling ? '' : 'paused'}`} onClick={handleToggleScroll}>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName')}</p>
                <p className="text-black">{t('feedbackPosition')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName1')}</p>
                <p className="text-black">{t('feedbackPosition1')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText1')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName2')}</p>
                <p className="text-black">{t('feedbackPosition2')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText2')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName3')}</p>
                <p className="text-black">{t('feedbackPosition3')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText3')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={feed} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="px-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName4')}</p>
                <p className="text-black">{t('feedbackPosition4')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText4')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName5')}</p>
                <p className="text-black">{t('feedbackPosition5')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText5')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={feed2} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName6')}</p>
                <p className="text-black">{t('feedbackPosition6')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText6')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={feed3} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName7')}</p>
                <p className="text-black">{t('feedbackPosition7')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText7')}</p>
          </div>
        </div>
        <div className="inline-block px-3">
          <div className="max-w-xs overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out feedback-card">
            <div className="inline-flex px-3 py-8 items-center">
              <img src={avatar1} alt="Avatar" className="w-full h-32 object-cover rounded-full" style={{ width: "70px", height: "70px" }} />
              <div className="p-3">
                <p className="font-semibold text-lg text-black">{t('feedbackName8')}</p>
                <p className="text-black">{t('feedbackPosition8')}</p>
              </div>
            </div>
            <p className="p-3 text-gray-700 feedback-text">{t('feedbackText8')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
