import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 
import { graphic, network } from './import';
import './volunteer.css';

const CustomButton = ({ text, onClick, color, className }) => {
  return (
    <div className='borderLine-line'>
      <button className={`custom-button ${className}`} style={{ backgroundColor: color }} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

function Volunteer() {
  const { t } = useTranslation(); 
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  useOutsideClick(formRef, () => setShowForm(false));

  const handleVolunteerRequest = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <>
      <div className="volunteer-container">
        <div className="volunteer-head bg-no-repeat bg-right py-0">
          <div className="content-container">
            <h2 className="font-bold text-white reach text-center md:text-left" style={{ fontFamily: "Rajdhani", lineHeight: "1", fontSize: "clamp(40px, 4vw, 70px)", fontWeight: "600" }}>{t('volunteerTitle')}</h2>
          </div>
        </div>
        {/* Experiences */}
        <div className="volunteer-item flex justify-center items-center">
          <div className="volunteer-image">
            <img src={graphic} alt="Graphic Design" className="object-cover w-full h-full rounded-r-md" />
          </div>
          <div className="volunteer-content" style={{ padding: "25px 25px 25px 25px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-right">{t('vipSpeakerAssistanceTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "right", fontSize: "12px" }}>{t('vipSpeakerAssistanceDescription')}</p>
          </div>
        </div>

        {/* Augmented Reality */}
        <div className="volunteer-item flex justify-center items-center">
          <div className="volunteer-content">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-left">{t('participantEngagementTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "left", fontSize: "12px" }}>{t('participantEngagementDescription')}</p>
          </div>
          <div className="volunteer-image">
            <img src={network} alt="Network Marketing" className="object-cover w-full h-full rounded-r-md" />
          </div>
        </div>
        
        <CustomButton
          text={t('requestVolunteer')}
          onClick={handleVolunteerRequest}
          color="#1CAE70"
          className="centered-button"
        />
        
        {showForm && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div ref={formRef} className="bg-white p-4 rounded-lg shadow-xl w-full max-w-4xl mx-auto" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
              <button className="absolute top-4 right-4 text-white" onClick={handleCloseForm} aria-label="Close form">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              <div className="form-container relative" style={{ paddingTop: '56.25%' }}>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSf2Hfaxi_wrZif7xJ-gn9rNUm5EPdwp0b6AzvZDxhKdk3xXmQ/viewform?embedded=true"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Google Form"
                >
                  Loading…
                </iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Volunteer;
