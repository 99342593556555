// import React from 'react';
// import backgroundImage from '../../assets/AYUK.png';

// function Connect() {
//   return (
//     <div className="flex flex-col md:flex-row mt-4 mr-12 mb-4 ml-12 bg-no-repeat bg-right rounded-md py-0 items-center connect" style={{ background: 'rgba(30, 30, 30, 1)', fontFamily: 'Syne', '@media (max-width: 768px)': { paddingLeft: '1rem', paddingRight: '1rem' } }}>
//       <div className="md:w-7/12 order-2 md:order-1 pl-8 md:pl-20 px-10" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
//         <h2 className="text-3xl md:text-5xl font-bold text-white mb-4" style={{textAlign: "left", '@media(max-width: 768px)': { textAlign: "center" }}} >We are always <br/> looking to connect <br/> with great people</h2>
//         <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
//         <button onClick={() => window.open("https://www.instagram.com", "_blank")} className="focus:outline-none  hover:bg-red-700 text-white font-bold py-2 px-3 rounded" style={{background: "#DD330B"}}>Instagram</button>
//           <button onClick={() => window.open("https://www.linkedin.com", "_blank")} className="focus:outline-none  hover:bg-red-700 text-white font-bold py-2 px-3 rounded" style={{background: "#DD330B"}}>LinkedIn</button>
//           <button onClick={() => window.open("https://www.twitter.com", "_blank")} className="focus:outline-none  hover:bg-red-700 text-white font-bold py-2 px-3 rounded" style={{background: "#DD330B"}}>Twitter</button>
//         </div>
//       </div>
//       <div className="md:w-5/12 order-1 md:order-2 mt-0 md:mt-0" style={{ margin: "0", padding: "0" }}>
//         <img src={backgroundImage} alt="Background" className="object-cover w-full h-full rounded-r-md" />
//       </div>

//     </div>
//   );
// }

// export default Connect;







import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphic, augmented, network, business, Virtual  } from './import';
import './connect.css'; 

function Connect() {
  const { t } = useTranslation();

  return (
    <div className="connect-wrapper" style={{ padding: '2rem 1rem' }}>
      <div className="connect-container">
        <div className="connect-head bg-no-repeat bg-right py-0">
          <div className="content-container">
            <h2 className="text-3xl md:text-4xl text-white reach text-center md:text-left" style={{ fontFamily: "Rajdhani", fontSize: "clamp(40px, 6vw, 70px)", fontWeight: "600"}}>{t('experiences')}</h2>
          </div>
        </div>
        {/* Experiences */}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 connect-item">
          <div className="connect-image">
            <img src={graphic} alt="Graphic Design" className="object-cover w-full h-full" />
          </div>
          <div className="connect-content" style={{ padding: "80px 25px 80px 80px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-right">{t('graphicDesignTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "right", fontSize: "12px" }}>{t('graphicDesignDescription')}</p>
          </div>
        </div>
        
        {/* Augmented Reality */}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 connect-item">
          <div className="connect-content" style={{ padding: "80px 80px 80px 25px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-left">{t('augmentedRealityTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "left", fontSize: "12px" }}>{t('augmentedRealityDescription')}</p>
          </div>
          <div className="connect-image">
            <img src={augmented} alt="Augmented Reality" className="object-cover w-full h-full rounded-r-md" />
          </div>
        </div>

        {/* Augmented Reality */}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 connect-item">

          <div className="connect-image">
            <img src={business} alt="Network Marketing" className="object-cover w-full h-full rounded-r-md" />
          </div>
          <div className="connect-content" style={{ padding: "80px 25px 80px 80px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-right">{t('businessManagement')}</h2>
            <p className='connect-p' style={{ textAlign: "right", fontSize: "12px" }}>{t('businessManagementDescription')}</p>
          </div>
        </div>

        {/* Augmented Reality */}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 connect-item">
          <div className="connect-content" style={{ padding: "80px 80px 80px 25px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-left">{t('networkMarketingTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "left", fontSize: "12px" }}>{t('networkMarketingDescription')}</p>
          </div>
          <div className="connect-image">
            <img src={network} alt="Augmented Reality" className="object-cover w-full h-full rounded-r-md" />
          </div>
        </div>
        
        {/* Network Marketing */}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 connect-item">
          <div className="connect-image">
            <img src={Virtual} alt="Network Marketing" className="object-cover w-full h-full rounded-r-md" />
          </div>
          <div className="connect-content" style={{ padding: "80px 25px 80px 80px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-right">{t('virtualRealityTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "right", fontSize: "12px" }}>{t('virtualRealityDescription')}</p>
          </div>
        </div>

        


        {/* Augmented Reality */}
        {/* <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 connect-item">
          <div className="connect-content" style={{ padding: "80px 80px 80px 25px"}}>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-left">{t('virtualRealityTitle')}</h2>
            <p className='connect-p' style={{ textAlign: "left", fontSize: "12px" }}>{t('virtualRealityDescription')}</p>
          </div>
          <div className="connect-image">
            <img src={Virtual} alt="Augmented Reality" className="object-cover w-full h-full rounded-r-md" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Connect;
