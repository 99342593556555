import React, { useState, useEffect, useRef  } from 'react';
import './locations.css';
import conn from "../../assets/conn.png";
import { useTranslation } from 'react-i18next';
import elipsecir from '../../assets/Ellipse4.png';
import elipsecis from '../../assets/Ellipse5.png';

const Locations = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        setShowForm(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleRegisterClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="gpt3__header section-biggest__padding relative" id="about">
      {/* Top ellipse */}
      <img src={elipsecis} alt="Ellipse Bottom" className="absolute top-0 right-0 hide-on-small" style={{ zIndex: 1, marginRight: '15%' }} />

      <div className="gpt3__header-image">
        <img src={conn} alt="Connection" />
      </div>
      <div className="gpt3__header-content">
        <p className="about-event">{t('aboutPushEvent')}</p>
        <h1 className="">{t('biggestWeekInInnovation')}</h1>
        <p>{t('pushEventDescription')}</p>

        <div className="gpt3__header-content__button">
          <button
            className="font-rajdhana bg-green-400 text-white font-bold px-4 mt-8 hover:bg-green-500"
            style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: "4rem", paddingRight: "4rem" }}
            onClick={handleRegisterClick}
          >
            {t('register')}
          </button>
        </div>
      </div>

      {/* Bottom ellipse */}
      <img src={elipsecir} alt="Ellipse Top" className="absolute bottom-0 left-0 hide-on-small" style={{ zIndex: 1, marginLeft: '40%' }} />

      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div ref={formRef} className="bg-white p-4 rounded-lg shadow-xl w-full max-w-4xl mx-auto" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <button className="absolute top-4 right-4 text-white" onClick={handleCloseForm}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className="form-container relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScwJ5oqsowGG7j9L2NCMG53mlDbFnfPlKTAQiXxg_a6W_Zm3A/viewform?embedded=true"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title="Google Form"
              >Loading…</iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Locations;
