import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import { ulevus, nervtek, giz, push, ultimate, ctec, new1, new2, new3 } from './imports';
import './speakers.css';
import { Divide } from '../../components';

const CustomButton = ({ text, onClick, color }) => {
  return (
    <div className='borderLine'>
      <button className="custom-button" style={{ backgroundColor: color }} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

const Speakers = () => {
  const [animate, setAnimate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);
  const { t } = useTranslation();

  useOutsideClick(formRef, () => setShowForm(false));

  const handlePartnerRequest = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const memberSponsor = [
    { id: 1, src: ulevus, alt: 'Image 1' },
    { id: 2, src: nervtek, alt: 'Image 2' },
    { id: 3, src: push, alt: 'Image 3' },
    { id: 4, src: giz, alt: 'Image 4' },
    { id: 5, src: ultimate, alt: 'Image 5' },
    { id: 6, src: ctec, alt: 'Image 6' },
    { id: 7, src: new1, alt: 'Image 7' },
    { id: 8, src: new2, alt: 'Image 8' },
    { id: 9, src: new3, alt: 'Image 9' },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col md:flex-row mt-4 mr-0 mb-4 ml-0 md:mr-0 md:ml-0 bg-no-repeat bg-right rounded-md py-0 connect flex-container section__padding" style={{ background: 'rgba(0, 0, 0, 1)', fontFamily: 'Syne', '@media (max-width: 768px)': { paddingLeft: '1rem', paddingRight: '1rem' } }}>
        <div className="reach-speaker md:w-full order-1 md:order-2 pl-8 pr-8 md:pl-20 md:pr-20 px-0 my-auto items-center" style={{ paddingTop: "25px", paddingBottom: "25px", paddingLeft: "30px", width: "100%", border: "1px solid #59E1A5" }}>
          <h2 className="text-3xl md:text-4xl font-bold text-white text-center md:text-center" style={{ color : "#59E1A5", fontFamily: "Rajdhani", fontSize: "clamp(40px, 6vw, 70px)", fontWeight: "600" }}>{t('partnersTitle')}</h2>
        </div>
      </div>
      <div className="container-speaker section__padding">
        <div className="scrolling-wrapper">
          {memberSponsor && memberSponsor.length > 0 ? (
            memberSponsor.map((image, index) => (
              <img key={index} src={image.src} alt={image.alt} className="image" />
            ))
          ) : (
            <p>{t('noSponsorsFound')}</p>
          )}
        </div>
      </div>
      <Divide />
      <CustomButton text={t('requestPartner')} onClick={handlePartnerRequest} color="#1CAE70" />
      
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div ref={formRef} className="bg-white p-4 rounded-lg shadow-xl w-full max-w-4xl mx-auto" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <button className="absolute top-4 right-4 text-white" onClick={handleCloseForm} aria-label="Close form">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className="form-container relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdHKftWu6htwMPRCneoDFVhe1iP5nqDUDPZdrW7ggtnmFNFDA/viewform?embedded=true"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title="Google Form"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Speakers;
