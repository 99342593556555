import React from 'react';
import { Facebook, Instagram, Tiktok, X } from './imports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './socialMedia.css';

const SocialMediaButtons = () => {
  const { t } = useTranslation();
  return (
    <div className="media-social flex flex-col bg-center bg-cover bg-no-repeat" style={{ background: "#1A2E35", fontFamily: "rajdhani"}} id="contact">
      <div className="flex flex-col md:flex-row bg-center bg-cover bg-no-repeat mx-auto md:w-3/4 space-y-10 md:space-y-0 md:space-x-10 max-w-full md:max-w-screen-lg" style={{ padding: "7% 7%" }}>
        <div className="w-full md:w-1/2">
          <h1 className="text-3xl md:text-5xl font-semibold text-white text-center md:text-left" style={{ fontSize: "50px", lineHeight: "50px" }}>{t('stayConnected')}</h1>
        </div>
        <div className="media-social-buttons w-full md:w-1/2 flex items-end justify-center md:justify-end">
          <div className="flex justify-center md:justify-start space-x-3">
            
            <button 
              className="px-4 py-4 font-semibold text-white inline-flex items-center space-x-2 rounded-full" 
              style={{ background: "#1A2E35", color: "#59E1A5", border: "1px solid #59E1A5" }}
              onClick={() => window.open('https://www.linkedin.com/company/push-cmr/', '_blank')}
            >
              <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="currentColor" d="M.56 0A.57.57 0 0 0 0 .56v14.88c0 .31.25.56.56.56h14.88a.57.57 0 0 0 .56-.56V.56a.57.57 0 0 0-.56-.56zm4.12 13.97H2.75V6.27h1.93v7.7zm-.97-8.8a1.02 1.02 0 1 1 .02-2.05 1.02 1.02 0 0 1-.02 2.05zm8.19 8.8h-1.94V9.63c0-.67-.01-1.54-.93-1.54-.93 0-1.07.73-1.07 1.49v3.3H5.92V6.27h1.93v1.1h.03c.27-.51.95-1.06 1.95-1.06 2.08 0 2.47 1.37 2.47 3.17v3.46z"/></svg>
            </button>
            <button className="px-4 py-4 font-semibold text-white inline-flex items-center space-x-2 rounded-full" style={{ background: "#1A2E35", color: "#59E1A5", border: "1px solid #59E1A5" }}  onClick={() => window.open('https://www.instagram.com/push_cmr?igsh=djd4aXg1MTY5cHJt', '_blank')}>
              <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" d="M8 0C5.829 0 5.556.01 4.703.048C3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7C.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297c.04.852.174 1.433.372 1.942c.205.526.478.972.923 1.417c.444.445.89.719 1.416.923c.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417c.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046c.78.035 1.204.166 1.486.275c.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485c.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598c-.28.11-.704.24-1.485.276c-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598a2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485c-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486c.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276c.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92a.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217a4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334a2.667 2.667 0 0 1 0-5.334"/></svg>
            </button>
            <button className="px-4 py-4 font-semibold text-white inline-flex items-center space-x-2 rounded-full" style={{ background: "#1A2E35", color: "#59E1A5", border: "1px solid #59E1A5", width: "fit-content" }}  onClick={() => window.open('https://www.facebook.com/profile.php?id=100086530743391', '_blank')}>
              <svg className="w-10 h-10 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
            </button>
            <button 
              className="px-4 py-4 font-semibold text-white inline-flex items-center space-x-2 rounded-full" 
              style={{ background: "#1A2E35", color: "#59E1A5", border: "1px solid #59E1A5" }}  
              onClick={() => {
                const phoneNumber = '+237655785954 / +237671770002';
                window.prompt('Copy the phone number:', phoneNumber);
              }}
            >
              <FontAwesomeIcon icon={faPhoneAlt} className="w-10 h-10" /> {/* Replace the SVG with Font Awesome phone icon */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaButtons;
