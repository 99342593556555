// import React from 'react';
// import { useTranslation } from 'react-i18next'; 
// import './session.css';
// import first from '../../assets/session_1.PNG';
// import second from '../../assets/session_2.PNG';
// import third from '../../assets/session_3.PNG';

// const Session = () => {
//   const { t } = useTranslation(); 

//   return (
//     <div className="section__head__padding relative overflow-hidden flex flex-col justify-center items-center" style={{ background: "#1CAE70", fontFamily: "rajdhani" }} id="sessions">
//       <div className="session-head bg-no-repeat bg-right py-0">
//         <div className="content-container">
//           <h2 className="text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ fontFamily: "Rajdhani", fontSize: "clamp(45px, 6vw, 70px)", fontWeight: "600" }}>{t('sessionTitle')}</h2>
//         </div>
//       </div>
//       <div className="sectiona__padding">
//         <img src={first} alt="Hero Background" className="object-cover w-full h-auto max-h-screen" />
//       </div>
//       <hr className="horizontal-divider" />
//       <div className="sectiona__padding">
//         <img src={second} alt="Hero Background" className="object-cover w-full h-auto max-h-screen" />
//       </div>
//       <hr className="horizontal-divider" />
//       <div className="sectiona__padding">
//         <img src={third} alt="Hero Background" className="object-cover w-full h-auto max-h-screen" />
//       </div>
//     </div>
//   );
// };

// export default Session;








// import React from 'react';
// import { useTranslation } from 'react-i18next'; 
// import './session.css';
// // import first from '../../assets/session_1.PNG';
// import first from '../../assets/1.png';
// import second from '../../assets/2.png';
// import third from '../../assets/3.png';
// // import second from '../../assets/session_2.PNG';
// // import third from '../../assets/session_3.PNG';

// const Session = () => {
//   const { t } = useTranslation(); 

//   return (
//     <div className="section__head__padding-sec text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "4rem" }} >
//       <div className="session-head bg-no-repeat bg-right py-0 my-8">
//         <div className="content-container">
//           <h2 className=" text-black reach text-center md:text-left" style={{ fontFamily: "Rajdhani", lineHeight: ".7", fontSize: "clamp(40px, 4vw, 70px)", fontWeight: "900"}}>{t('sessionTitle')}</h2>
//         </div>
//       </div>
//       <div
//         className="section__head__padding text-3xl md:text-4xl text-black reach text-center md:text-left"
//         style={{
//           background: "#1CAE70",
//           fontFamily: "Rajdhani",
//           padding: "0 6rem 1rem 6rem"
//         }}
//       >
//         <div
//           className="session_img relative overflow-hidden flex flex-col sm:flex-row justify-center items-center mb-8 space-x-0 sm:space-x-8 w-full"
//           style={{
//             fontFamily: "Rajdhani",
//             backgroundImage: `url(${first})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: "100%",
//             minHeight: '95vh'
//           }}
//           id="sessions"
//         >
//           <div
//             className="journey-One flex flex-col justify-start sm:w-1/3 w-full"
//             style={{ width: '300px' }}
//           >
//             <h1
//               className="text-6xl sm:text-6xl font-bold text-gray-800"
//               style={{
//                 fontFamily: "Rajdhani",
//                 textAlign: 'left',
//                 lineHeight: '1.2',
//                 fontWeight: '700',
//                 color: "#59E1A5",
//                 fontSize: "clamp(40px, 15vw, 570px)"
//               }}
//             >
//               DAY
//             </h1>
//             <p
//               className="text-white text-left"
//               style={{
//                 fontFamily: "Rajdhani",
//                 textAlign: 'left',
//                 lineHeight: '.5',
//                 fontWeight: '700',
//                 color: "#59E1A5",
//                 fontSize: "clamp(40px, 15vw, 570px)"
//               }}
//             >
//               01
//             </p>
//             <div
//               className="hidden sm:block"
//               style={{ marginRight: '4rem', padding: '3rem 0', width: '300px' }}
//             ></div>
//           </div>

//           <div className="padding__mobile-journey flex flex-col space-y-4 large-text w-full sm:w-1/2 ml-auto items-start sm:items-end p-4 sm:p-0">
//             <div className="hidden sm:block w-96 py-0 "></div>

//             {/* Projects Executed */}
//             <div className="flex flex-col items-start  sm:items-start mb-4">
//               <div className="flex items-center">
//                 <p
//                   className="large-text font-bold text-white"
//                   style={{
//                     fontFamily: "Rajdhani",
//                     color: "#59E1A5",
//                     fontSize: 'clamp(32px, 5vw, 48px)',
//                   }}
//                 >
//                   09:00 am - 10:00 am
//                 </p>
//               </div>
//               <p
//                 className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//                 style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//               >
//                 Opening ceremony
//               </p>
//             </div>

//             {/* Happy Clients */}
//             <div className="flex flex-col items-start sm:items-start mb-4">
//               <div className="flex items-center">
//                 <p
//                   className="large-text font-bold text-white"
//                   style={{
//                     fontFamily: "Rajdhani",
//                     color: "#59E1A5",
//                     fontSize: 'clamp(32px, 5vw, 48px)',
//                   }}
//                 >
//                   10:00 am - 01:00 pm
//                 </p>
//               </div>
//               <p
//                 className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//                 style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//               >
//                 Workshop on networking and personal growth
//               </p>
//             </div>

//             {/* Lunch Break */}
//             <div className="flex flex-col items-start mb-4">
//               <div className="flex items-center">
//                 <p
//                   className="large-text font-bold text-white"
//                   style={{
//                     fontFamily: "Rajdhani",
//                     color: "#59E1A5",
//                     fontSize: 'clamp(32px, 5vw, 48px)',
//                   }}
//                 >
//                   01:00 pm - 03:00 pm
//                 </p>
//               </div>
//               <p
//                 className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//                 style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px"}}
//               >
//                 Lunch break and networking
//               </p>
//             </div>

//             {/* Meetups */}
//             <div className="flex flex-col items-start mb-4">
//               <div className="flex items-center">
//                 <p
//                   className="large-text font-bold text-white"
//                   style={{
//                     fontFamily: "Rajdhani",
//                     color: "#59E1A5",
//                     fontSize: 'clamp(32px, 5vw, 48px)',
//                   }}
//                 >
//                   03:00 pm - 06:00 pm
//                 </p>
//               </div>
//               <p
//                 className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//                 style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px"}}
//               >
//                 Meetups with renowned personalities
//               </p>
//             </div>

//             {/* <div className="hidden sm:block w-96 py-8 mt-12"></div> */}
//           </div>
//         </div>
//       </div>

//     <hr className="horizontal-divider" />




//     <div className="section__head__padding  text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "0 6rem 1rem 6rem" }} > 
//       <div
//         className=" session_img relative overflow-hidden flex flex-col sm:flex-row justify-center items-center mb-8 space-x-0 sm:space-x-8 w-full"
//         style={{
//           fontFamily: "Rajdhani",
//           backgroundImage: `url(${second})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: "100%",
//           minHeight: '95vh'
//         }}
//         id="sessions"
//       >
//         <div
//           className="journey-One flex flex-col justify-start sm:w-1/3 w-full"
//           style={{ width: '300px' }}
//         >
//           <h1
//             className="text-6xl sm:text-6xl font-bold text-gray-800"
//             style={{
//               fontFamily: "Rajdhani",
//               textAlign: 'left',
//               color: '#fff',
//               lineHeight: '1.2',
//               fontWeight: '700',
//               color: "#59E1A5",
//               fontSize: "clamp(40px, 15vw, 570px)"
//             }}
//           >
//             DAY 
//           </h1>
//           <p className="text-white text-left" style={{
//               fontFamily: "Rajdhani",
//               textAlign: 'left',
//               color: '#fff',
//               lineHeight: '.5',
//               fontWeight: '700',
//               color: "#59E1A5",
//               fontSize: "clamp(40px, 15vw, 570px)"
//             }}>
//             02
//           </p>
//           <div
//             className="hidden sm:block"
//             style={{ marginRight: '4rem', padding: '3rem 0', width: '300px' }}
//           ></div>
//         </div>

//         <div className="padding__mobile-journey flex flex-col space-y-4 large-text w-full sm:w-1/2 ml-auto items-start sm:items-end p-4 sm:p-0">
//           <div className="hidden sm:block w-96 py-0 "></div>

//           {/* Projects Executed */}
//           <div className="flex flex-col items-start sm:items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 09:00 am - 02:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px"}}
//             >
//               Exhibitions
//             </p>
//           </div>

//           {/* Happy Clients */}
//           <div className="flex flex-col items-start sm:items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 02:00 pm - 03:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Up in meetings with financial institutions and investors
//             </p>
//           </div>

//           {/* Lunch Break */}
//           <div className="flex flex-col items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 03:00 pm - 05:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Conference session on industry trends as well as Networking event with structured activities to facilitate connections
//             </p>
//           </div>

//           {/* Meetups */}
//           <div className="flex flex-col items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 03:00 pm - 06:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Meetups with renowned personalities
//             </p>
//           </div>
//         </div>

//       </div>
//     </div>

//     <hr className="horizontal-divider" />


//     <div className="section__head__padding  text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "0 6rem 1rem 6rem" }} > 
//       <div
//         className=" session_img relative overflow-hidden flex flex-col sm:flex-row justify-center items-center mb-8 space-x-0 sm:space-x-8 w-full"
//         style={{
//           fontFamily: "Rajdhani",
//           backgroundImage: `url(${third})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: "100%",
//           minHeight: '95vh' 
//         }}
//         id="sessions"
//       >
//         <div
//           className="journey-One flex flex-col justify-start sm:w-1/3 w-full"
//           style={{ width: '300px' }}
//         >
//           <h1
//             className="text-6xl sm:text-6xl font-bold text-gray-800"
//             style={{
//               fontFamily: "Rajdhani",
//               textAlign: 'left',
//               color: '#fff',
//               lineHeight: '1.2',
//               fontWeight: '700',
//               color: "#59E1A5",
//               fontSize: "clamp(40px, 15vw, 570px)"
//             }}
//           >
//             DAY 
//           </h1>
//           <p className="text-white text-left" style={{
//               fontFamily: "Rajdhani",
//               textAlign: 'left',
//               color: '#fff',
//               lineHeight: '.5',
//               fontWeight: '700',
//               color: "#59E1A5",
//               fontSize: "clamp(40px, 15vw, 570px)"
//             }}>
//             03
//           </p>
//           <div
//             className="hidden sm:block"
//             style={{ marginRight: '4rem', padding: '3rem 0', width: '300px' }}
//           ></div>
//         </div>

//         <div className="padding__mobile-journey flex flex-col space-y-4 large-text w-full sm:w-1/2 ml-auto items-start sm:items-end p-4 sm:p-0">
//           <div className="hidden sm:block w-96 py-0 "></div>

//           {/* Projects Executed */}
//           <div className="flex flex-col items-start sm:items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 09:00 am - 11:00 am
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Pitch hour sessions for startups and entrepreneurs to showcase their ideas
//             </p>
//           </div>

//           {/* Happy Clients */}
//           <div className="flex flex-col items-start sm:items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 11:00 am - 02:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Final opportunity to visit exhibitions and network with attendees
//             </p>
//           </div>

//           {/* Lunch Break */}
//           <div className="flex flex-col items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 02:00 pm - 03:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Up in meetings
//             </p>
//           </div>

//           {/* Meetups */}
//           <div className="flex flex-col items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 03:00 pm - 04:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Conference
//             </p>
//           </div>

//           {/* Meetups */}
//           <div className="flex flex-col items-start mb-4">
//             <div className="flex items-center">
//               <p
//                 className="large-text font-bold text-white"
//                 style={{
//                   fontFamily: "Rajdhani",
//                   color: "#59E1A5",
//                   fontSize: 'clamp(32px, 5vw, 48px)',
//                 }}
//               >
//                 06:00 pm - 07:00 pm
//               </p>
//             </div>
//             <p
//               className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
//               style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px" }}
//             >
//               Cocktail evening with live music and networking opportunities
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>

//   </div>
//   );
// };

// export default Session;










import React from 'react';
import { useTranslation } from 'react-i18next';
import './session.css';
import first from '../../assets/1.png';
import second from '../../assets/2.png';
import third from '../../assets/3.png';

const Session = () => {
  const { t } = useTranslation();

  return (
      <div className="section__head__padding-sec text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "4rem" }} >
        <div className="session-head bg-no-repeat bg-right py-0 my-8">
          <div className="content-container">
            <h2  className=" text-black reach text-center md:text-left" style={{ fontFamily: "Rajdhani", lineHeight: ".7", fontSize: "clamp(40px, 4vw, 70px)", fontWeight: "900"}}>{t('sessionTitle')}</h2>
          </div>
        </div>

      <div className="section__head__padding text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "0 6rem 1rem 6rem" }} >
        <div className="session_img relative overflow-hidden flex flex-col sm:flex-row justify-center items-center mb-8 space-x-0 sm:space-x-8 w-full" style={{ fontFamily: "Rajdhani", backgroundImage: `url(${first})`, backgroundSize: 'cover', backgroundPosition: 'center', justifyContent: 'center', alignItems: 'center', height: "100%", minHeight: '95vh' }} id="sessions">
          <div className="journey-One flex flex-col justify-start sm:w-1/3 w-full" style={{ width: '300px' }}>
            <h1 className="text-6xl sm:text-6xl font-bold text-gray-800" style={{ fontFamily: "Rajdhani", textAlign: 'left', lineHeight: '1.2', fontWeight: '700', color: "#59E1A5", fontSize: "clamp(40px, 15vw, 570px)" }}>
              {t('day')} 
            </h1>
            <p className="text-white text-left" style={{ fontFamily: "Rajdhani", textAlign: 'left', lineHeight: '.5', fontWeight: '700', color: "#59E1A5", fontSize: "clamp(40px, 15vw, 570px)" }}>
              01
            </p>
            <div className="hidden sm:block" style={{ marginRight: '4rem', padding: '3rem 0', width: '300px' }}></div>
          </div>

          <div className="padding__mobile-journey flex flex-col space-y-4 large-text w-full sm:w-1/2 ml-auto items-start sm:items-end p-4 sm:p-0">
            <div className="hidden sm:block w-96 py-0 "></div>

            <div className="flex flex-col items-start  sm:items-start mb-4">
              <div className="flex items-center">
                <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                  09:00 am - 10:00 am
                </p>
              </div>
              <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
                {t('openingCeremony')}
              </p>
            </div>

            <div className="flex flex-col items-start sm:items-start mb-4">
              <div className="flex items-center">
                <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                  10:00 am - 01:00 pm
                </p>
              </div>
              <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
                {t('workshop')}
              </p>
            </div>

            <div className="flex flex-col items-start mb-4">
              <div className="flex items-center">
                <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                  01:00 pm - 03:00 pm
                </p>
              </div>
              <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px"}}>
                {t('lunchBreak')}
              </p>
            </div>

            <div className="flex flex-col items-start mb-4">
              <div className="flex items-center">
                <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                  03:00 pm - 06:00 pm
                </p>
              </div>
              <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px"}}>
                {t('meetups')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="horizontal-divider" />

      <div className="section__head__padding text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "0 6rem 1rem 6rem" }} >
        <div className="session_img relative overflow-hidden flex flex-col sm:flex-row justify-center items-center mb-8 space-x-0 sm:space-x-8 w-full" style={{ fontFamily: "Rajdhani", backgroundImage: `url(${second})`, backgroundSize: 'cover', backgroundPosition: 'center', justifyContent: 'center', alignItems: 'center', height: "100%", minHeight: '95vh' }} id="sessions">
          <div className="journey-One flex flex-col justify-start sm:w-1/3 w-full" style={{ width: '300px' }}>
            <h1 className="text-6xl sm:text-6xl font-bold text-gray-800" style={{ fontFamily: "Rajdhani", textAlign: 'left', lineHeight: '1.2', fontWeight: '700', color: "#59E1A5", fontSize: "clamp(40px, 15vw, 570px)" }}>
              {t('day')} 
            </h1>
            <p className="text-white text-left" style={{ fontFamily: "Rajdhani", textAlign: 'left', lineHeight: '.5', fontWeight: '700', color: "#59E1A5", fontSize: "clamp(40px, 15vw, 570px)" }}>
              02
            </p>
            <div className="hidden sm:block" style={{ marginRight: '4rem', padding: '3rem 0', width: '300px' }}></div>
          </div>

          <div className="padding__mobile-journey flex flex-col space-y-4 large-text w-full sm:w-1/2 ml-auto items-start sm:items-end p-4 sm:p-0" >
            <div className="another__padding hidden sm:block w-96" style={{padding: "2rem 0"}}></div>

            <div className="flex flex-col items-start  sm:items-start mb-4"> 
              <div className="flex items-center">
                <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                  09:00 am - 02:00 pm
                </p>
              </div>
              <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px"}}>
                {t('exhibitions')}
              </p>
            </div>

            <div className="flex flex-col items-start sm:items-start mb-4">
              <div className="flex items-center">
                <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                  02:00 pm - 03:00 pm
                </p>
              </div>
              <p
                className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
                style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}
              >
                {t('meetings')}
              </p>
            </div>

            {/* Conference session */}
            <div className="flex flex-col items-start mb-4">
              <div className="flex items-center">
                <p
                  className="large-text font-bold text-white"
                  style={{
                    fontFamily: "Rajdhani",
                    color: "#59E1A5",
                    fontSize: 'clamp(32px, 5vw, 48px)',
                  }}
                >
                  03:00 pm - 05:00 pm
                </p>
              </div>
              <p
                className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
                style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}
              >
                {t('conferenceSession')}
              </p>
            </div>

            {/* Meetups with renowned personalities */}
            <div className="flex flex-col items-start mb-4">
              <div className="flex items-center">
                <p
                  className="large-text font-bold text-white"
                  style={{
                    fontFamily: "Rajdhani",
                    color: "#59E1A5",
                    fontSize: 'clamp(32px, 5vw, 48px)',
                  }}
                >
                  05:00 pm - 06:00 pm
                </p>
              </div>
              <p
                className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
                style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}
              >
                {t('meetups')}
              </p>
              
            </div>

            {/* Cocktail evening */}
            <div className="flex flex-col items-start mb-4">
              <div className="flex items-center">
                <p
                  className="large-text font-bold text-white"
                  style={{
                    fontFamily: "Rajdhani",
                    color: "#59E1A5",
                    fontSize: 'clamp(32px, 5vw, 48px)',
                  }}
                >
                  06:00 pm - 07:00 pm
                </p>
              </div>
              <p
                className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left"
                style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}
              >
                {t('cocktailEvening')}
              </p>
            </div>
            <div className="another__padding hidden sm:block w-96" style={{padding: "2rem 0"}}></div>
        </div>

      </div>
    </div>

    <hr className="horizontal-divider" />


    <div className="section__head__padding  text-3xl md:text-4xl text-black reach text-center md:text-left" style={{ background: "#1CAE70", fontFamily: "Rajdhani", padding: "0 6rem 1rem 6rem" }} > 
      <div
        className=" session_img relative overflow-hidden flex flex-col sm:flex-row justify-center items-center mb-8 space-x-0 sm:space-x-8 w-full"
        style={{
          fontFamily: "Rajdhani",
          backgroundImage: `url(${third})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          height: "100%",
          minHeight: '95vh' 
        }}
        id="sessions"
      >
        <div
          className="journey-One flex flex-col justify-start sm:w-1/3 w-full"
          style={{ width: '300px' }}
        >
          <h1
            className="text-6xl sm:text-6xl font-bold text-gray-800"
            style={{
              fontFamily: "Rajdhani",
              textAlign: 'left',
              color: '#fff',
              lineHeight: '1.2',
              fontWeight: '700',
              color: "#59E1A5",
              fontSize: "clamp(40px, 15vw, 570px)"
            }}
          >
            {t('day')} 
          </h1>
          <p className="text-white text-left" style={{
              fontFamily: "Rajdhani",
              textAlign: 'left',
              color: '#fff',
              lineHeight: '.5',
              fontWeight: '700',
              color: "#59E1A5",
              fontSize: "clamp(40px, 15vw, 570px)"
            }}>
            03
          </p>
          <div
            className="hidden sm:block"
            style={{ marginRight: '4rem', padding: '3rem 0', width: '300px' }}
          ></div>
        </div>

        <div className="padding__mobile-journey flex flex-col space-y-4 large-text w-full sm:w-1/2 ml-auto items-start sm:items-end p-4 sm:p-0">
          <div className="another__padding hidden sm:block w-96 py-8 "></div>

          {/* Projects Executed */}
          <div className="flex flex-col items-start sm:items-start mb-4">
            <div className="flex items-center">
              <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                09:00 am - 11:00 am
              </p>
            </div>
            <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
              {t('pitchHour')}
            </p>
          </div>

          {/* Happy Clients */}
          <div className="flex flex-col items-start sm:items-start mb-4">
            <div className="flex items-center">
              <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                11:00 am - 02:00 pm
              </p>
            </div>
            <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
              {t('finalOpportunity')}
            </p>
          </div>

          {/* Lunch Break */}
          <div className="flex flex-col items-start mb-4">
            <div className="flex items-center">
              <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                02:00 pm - 03:00 pm
              </p>
            </div>
            <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
              {t('upInMeetings')}
            </p>
          </div>

          {/* Meetups */}
          <div className="flex flex-col items-start mb-4">
            <div className="flex items-center">
              <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                03:00 pm - 04:00 pm
              </p>
            </div>
            <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
              {t('conferenceSession')}
            </p>
          </div>

          {/* Meetups */}
          <div className="flex flex-col items-start mb-4">
            <div className="flex items-center">
              <p className="large-text font-bold text-white" style={{ fontFamily: "Rajdhani", color: "#59E1A5", fontSize: 'clamp(32px, 5vw, 48px)' }}>
                06:00 pm - 07:00 pm
              </p>
            </div>
            <p className="para_width text-white mt-2 text-base sm:text-lg md:text-xl pb-2 text-left" style={{ fontFamily: 'Montserrat-Bold', color: "#59E1A5", width: "400px", fontSize: "16px" }}>
              {t('cocktailEvening')}
            </p>
          </div>
          <div className="another__padding hidden sm:block w-96 py-8"></div>
        </div>
      </div>
    </div>
  ` </div>
  );
};

export default Session;
