import React, { useState, useEffect, useRef  } from 'react';
import './team.css';
import { TeamInfo } from '../../components';
import { useTranslation } from 'react-i18next';
import { team1, team2, team3, team4, team5, team6, team7, team8, team9, team10, team11, team12 } from './imports';

const CustomButton = ({ text, onClick, color, className }) => {
  return (
    <div className='borderLine-line-speaker'>
      <button className={`custom-button-speaker ${className}`} style={{ backgroundColor: color }} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

const Team = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        setShowForm(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const handleSpeakerRequest = () => {
    setShowForm(true); 
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };  

  const teamMembers = [
    { id: 2, photos: team2, name: t('speaker2Name'), address: t('speaker2Address'), talk: t('speaker2Talk') },
    { id: 3, photos: team3, name: t('speaker3Name'), address: t('speaker3Address'), talk: t('speaker3Talk') },
    { id: 4, photos: team4, name: t('speaker4Name'), address: t('speaker4Address'), talk: t('speaker4Talk') },
    { id: 5, photos: team5, name: t('speaker5Name'), address: t('speaker5Address'), talk: t('speaker5Talk') },
    { id: 6, photos: team6, name: t('speaker6Name'), address: t('speaker6Address'), talk: t('speaker6Talk') },
    { id: 7, photos: team7, name: t('speaker7Name'), address: t('speaker7Address'), talk: t('speaker7Talk') },
    { id: 8, photos: team8, name: t('speaker8Name'), address: t('speaker8Address'), talk: t('speaker8Talk') },
    { id: 9, photos: team9, name: t('speaker9Name'), address: t('speaker9Address'), talk: t('speaker9Talk') },
    { id: 1, photos: team1, name: t('speaker1Name'), address: t('speaker1Address'), talk: t('speaker1Talk') },
    { id: 10, photos: team10, name: t('speaker10Name'), address: t('speaker10Address'), talk: t('speaker10Talk') },
    { id: 11, photos: team11, name: t('speaker11Name'), address: t('speaker11Address'), talk: t('speaker11Talk') },
    // { id: 12, photos: team12, name: t('speaker12Name'), address: t('speaker12Address'), talk: t('speaker12Talk') }
  ];

  return (
    <div className="ulev__team" id="speakers">
      <div className="activities-heada bg-no-repeat bg-right py-0">
        <div className="content-container">
          <h2 className="text-3xl md:text-4xl text-white reach text-center md:text-left" style={{ fontFamily: "Rajdhani", fontSize: "clamp(40px, 6vw, 70px)", fontWeight: "600"}}>{t('speakersTitle')}</h2>
        </div>
      </div>
      <div className='ulev__team-container'>
        <div className='ulev__team-container_group'>
          {teamMembers.map((member) => (
            // <TeamInfo key={member.id} photos={member.photos} name={member.name} address={member.address} talk={member.talk} />
            <TeamInfo key={member.id} photos={member.photos} name={member.name} address={member.address} />
          ))}
        </div>
      </div>
      <CustomButton
        text={t('requestSpeaker')}
        onClick={handleSpeakerRequest}
        color="#1CAE70"
        className="custom-button-speaker-left"
      />

      {/* Google Form Modal */}
      {/* {showFormModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-4xl mx-auto" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <button className="absolute top-4 right-4 text-white" onClick={closeModal}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className="form-container relative" style={{ paddingTop: '56.25%' }}>
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSc7TXqF5hg8UioRvt133Npg7vbBXpF4aEMwnzDSj7Lazajkjw/viewform?embedded=true" 
                width="100%" 
                height="100%" 
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0" 
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title="Google Form"
              >Loading…</iframe>
            </div>
          </div>
        </div>
      )} */}

      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div ref={formRef} className="bg-white p-4 rounded-lg shadow-xl w-full max-w-4xl mx-auto" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <button className="absolute top-4 right-4 text-white" onClick={handleCloseForm}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className="form-container relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSc7TXqF5hg8UioRvt133Npg7vbBXpF4aEMwnzDSj7Lazajkjw/viewform?embedded=true"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title="Google Form"
              >Loading…</iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Team;
