import React from 'react';
import './teaminfo.css';

const TeamInfo = ({
  photos,
  name,
  address,
  talk
}) => {
  return (
    <div className='ulev__blog-container_article'>
      <div className='ulev__blog-container_article-image'>
        <img src={photos} alt="team_image" />
      </div>
      <div className='ulev__blog-container_article-content'>
        <div className='detail'>
          <h3 className='name' style={{ fontWeight: "600"}}>{name}</h3>
          <h5 className='address' style={{ fontWeight: "400"}}>{address}</h5>
          <h6 className='talk' style={{ fontWeight: "400"}}>{talk}</h6>
        </div>
      </div>
    </div>
  );
};

export default TeamInfo;