import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { first, second  } from './imports';
import './activities.css'; 

function Activities() {
  const { t } = useTranslation(); 

  return (
    <>
      <div className="activities-container">
        <div className="activities-heads bg-no-repeat bg-right py-0">
          <div className="content-container">
            <h2 className="text-3xl md:text-4xl text-white reach text-center md:text-left" style={{ fontFamily: "Rajdhani", fontSize: "clamp(40px, 6vw, 70px)", fontWeight: "600"}}>{t('activitiesTitle')}</h2>
          </div>
        </div>
        {/* Day 1*/}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 activities-item">
          <div className="activities-content bordered" style={{marginRight: '20px', padding: "70px 50px 25px 70px"}}>
            <h1 className="text-3xl md:text-4xl font-bold text-white mb-2 reach text-center md:text-left" style={{fontSize: "40px", fontWeight: "400"}}>{t('day1Title')}</h1>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-2 reach text-center md:text-left" style={{fontSize: "40px", fontWeight: "600"}}>{t('day1Subtitle')}</h2>
            <p className='connect-p' style={{ textAlign: "left", fontSize: "12px", fontWeight: "400px" }}>{t('day1Description')}</p>
          </div>
          <div className="activities-image">
            <img src={first} alt="Network Marketing" className="object-cover w-full h-full" />
          </div>
        </div>

        {/* Day 2*/}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 activities-item">
          <div className="activities-image">
            <img src={second} alt="Graphic Design" className="object-cover w-full h-full" />
          </div>
          <div className="activities-content bordered" style={{marginLeft: '20px', padding: "70px 70px 25px 70px"}}>
            <h1 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-right" style={{fontSize: "40px", fontWeight: "400"}}>{t('day2Title')}</h1>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-right" style={{fontSize: "40px", fontWeight: "600"}}>{t('day2Subtitle')}</h2>
            <p className='connect-p' style={{ textAlign: "right", fontSize: "12px", fontWeight: "400px" }}>{t('day2Description')}</p>
          </div>
        </div>
        
        {/* Day 3*/}
        <div className="flex flex-col md:flex-row bg-no-repeat bg-right py-0 activities-item">
          <div className="activities-content bordered" style={{marginRight: '20px', padding: "70px 50px 25px 70px"}}>
            <h1 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-left" style={{fontSize: "40px", fontWeight: "400"}}>{t('day3Title')}</h1>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 reach text-center md:text-left" style={{fontSize: "40px", fontWeight: "600"}}>{t('day3Subtitle')}</h2>
            <p className='connect-p' style={{ textAlign: "left", fontSize: "12px", fontWeight: "400px" }}>{t('day3Description')}</p>
          </div>
          <div className="activities-image">
            <img src={first} alt="Network Marketing" className="object-cover w-full h-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Activities;
