import React from 'react';
import { useTranslation } from 'react-i18next';
import './creatives.css'; 

const Creatives = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='push_scroll'>
        <div className="scrolling-text-container">
          <h1 className="scrolling-text">{t('pushEvent2024')}</h1>
        </div>
        <div className="scrolling-text-container-second">
          <h1 className="scrolling-text-second">{t('pushEvent2024')}</h1>
        </div>
      </div>
    </>
  );
}

export default Creatives;
